<template lang="pug">
  .sidebar-input-wrapper.d-flex(:class="classes")
    .sidebar-input-wrapper-label(v-if="$slots.label || label")
      slot(name="label")
        label {{ $te(label) ? $t(label) : label }}
    span.epic-simple-input
      .select-arrow
        i(class="fa fa-angle-down" aria-hidden="true")
      select(v-model="value" @change="$emit('change', $event)")
        option(v-for="option in options" :value="option.value") {{ option.key }}
</template>
<script>
  import itemMixin from '@/editor/mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      options: { type: Array, required: true },
      eventDriven: { type: Boolean, default: false },
      label: { type: String, default: null },
      horizontal: { type: Boolean, default: false },
    },
    computed: {
      setter() {
        return this.property.includes('globalStyle') ? 'setValueOf' : 'smartSetValueOf';
      },
      getter() {
        return this.property.includes('globalStyle') ? 'getValueOf' : 'smartGetValueOf';
      },
      value: {
        get() {
          return this[this.getter](this.property);
        },
        set(value) {
          if (!this.eventDriven) {
            this[this.setter](this.property, value);
          } else {
            this.$emit('input', value);
          }
        },
      },
      classes() {
        return this.horizontal
          ? ['flex-row', 'align-items-center', 'justify-content-between', 'horizontal-layout']
          : ['flex-column', 'align-items-start'];
      },
    },
  };
</script>

<style lang="sass" scoped>
  .sidebar
    .sidebar-input-wrapper
      label
        position: relative
        left: 0
        top: 0
        margin-bottom: .25rem
      .epic-simple-input
        .select-arrow
          right: .25rem
          top: calc(50% - 0.2rem)
      &.horizontal-layout
        .epic-simple-input
          position: relative
          .select-arrow
            right: .5rem
            top: 0.4rem
</style>
